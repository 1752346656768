import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DelegateCustomer from '../../models/DelegateCustomer';
import { AuthService } from '../../services/auth.service';
import ClientsList from './ClientsList';
import './DropdownAutocomplete.css';

export default function DropdownAutocomplete() {
  const [clientsOfTheDelegate, setClientsOfTheDelegate] = useState<
    DelegateCustomer[]
  >([]);
  const [inputQuery, setInputQuery] = useState('');
  const [searchResults, setSearchResults] = useState<DelegateCustomer[]>([]);
  const [showList, setShowList] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  //TODO: @Paula - Cambiar el email por el email del usuario logueado

  useEffect(() => {
    let emailDelegate =
      localStorage.getItem('email') || process.env.DELEGATE_CLIENT_EMAIL || '';
    const isCustomUser = localStorage.getItem('isCustomUser');
    if (isCustomUser === 'true') {
      emailDelegate = 'cmartinez@mesoestetic.com' || emailDelegate; 
    }
    AuthService.GetCustomersOfDelegate(emailDelegate).then((response) => {
      setClientsOfTheDelegate(response.data as DelegateCustomer[]);
      setSearchResults(response.data as DelegateCustomer[]);
    });
  }, []);

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    setInputQuery(event.target.value);
    setSearchResults(
      clientsOfTheDelegate.filter((client) =>
        client.nameDelegateClient
          .toLocaleLowerCase()
          .includes(event.target.value.toLocaleLowerCase()),
      ),
    );
  }

  function handleClientClick(client: DelegateCustomer) {
    saveClientDelegateInformation(client);
    navigate('/products');
  }

  function saveClientDelegateInformation(client: DelegateCustomer) {
    localStorage.setItem('selectedNameClient', client.nameDelegateClient);
    localStorage.setItem('idCompanyClient', client.idCompany);
    localStorage.setItem('idCountryClient', client.idCountry);
    localStorage.setItem('idDelegateClient', client.idDelegateClient);
  }

  return (
    <div style={{ width: '100%', maxWidth: '400px', position: 'relative' }}>
      <div className="input-container">
        <input
          type="text"
          className="styled-input"
          placeholder={t('dropdown_autocomplete.input')}
          onChange={handleInputChange}
          onFocus={() => setShowList(true)} 
        />
        <i
          className="pi pi-angle-down icon"
          onClick={() => setShowList(!showList)}
        ></i>
      </div>

      {(showList || (inputQuery !== '' && searchResults.length > 0)) && (
        <div className="dropdown-list">
          <ClientsList
            clients={searchResults}
            onClientClick={handleClientClick}
          />
        </div>
      )}
    </div>
  );
}
