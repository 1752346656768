import React, { ReactNode, createContext, useContext, useState } from 'react';
import FilterType from '../models/FilterType';

interface FilterContextType {
  filters: FilterType;
  setFilters: React.Dispatch<React.SetStateAction<FilterType>>;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const FilterProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [filters, setFilters] = useState<FilterType>({
    platform: [],
    productType: [],
    idLanguage: [],
    isOnDemand: undefined,
    available: undefined,
    endOfStock: undefined, 
  });

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter debe ser usado dentro de un FilterProvider');
  }
  return context;
};
