import axios from 'axios';

export const PlatformService = {
  GetAllPlatformsByLanguage: async (language: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/platform/language/${language}`,
    );
    return response;
  },
  GetPlatformsOfTheProducts: async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/product/platforms`,
    );
    return response;
  },
};
