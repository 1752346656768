import React from 'react';
import DelegateCustomer from '../../models/DelegateCustomer';
import { ScrollPanel } from 'primereact/scrollpanel';
import './ClientsList.css';

type ClientListProps = {
  clients: DelegateCustomer[];
  onClientClick: (client: DelegateCustomer) => void;
};

export default function ClientsList({
  clients,
  onClientClick,
}: ClientListProps) {
  return (
    <div className="dropdown-list">
      <ScrollPanel style={{ width: '100%', height: '250px' }}>
        {clients.map((client) => (
          <div
            key={client.idDelegateClient}
            className="client-item"
            onClick={() => onClientClick(client)}
          >
            <p>
              <strong>{client.nameDelegateClient.toLocaleLowerCase()}</strong>
            </p>
            <hr className="styled-hr"></hr>
          </div>
        ))}
      </ScrollPanel>
    </div>
  );
}
