import { createContext, useContext } from 'react';

interface BusinessUnitContextProps {
  businessUnitKey: string;
  businessUnitName: string;
  setBusinessUnitKey: (businessUnitKey: string) => void;
  setBusinessUnitName: (businessUnitName: string) => void;
}

export const BusinessUnitContext = createContext<
  BusinessUnitContextProps | undefined
>(undefined);

export function useBusinessUnit() {
  const context = useContext(BusinessUnitContext);
  if (!context) {
    throw new Error('useProductType must be used within a ProductTypeProvider');
  }
  return context;
}
