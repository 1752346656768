import { ScrollPanel } from 'primereact/scrollpanel';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useFilter } from '../../context/FilterContext';
import FilterType from '../../models/FilterType';
import ProductType from '../../models/ProductType';
import { PlatformService } from '../../services/platform.service';
import { ProductTypeService } from '../../services/productType.service';
import { useTranslation } from 'react-i18next';
import './FilterSideBar.css';
import { LanguageService } from '../../services/language.service';



const FilterSideBar = () => {
  const { t } = useTranslation();
  const [idLanguageUser, setIdLanguageUser] = useState<string>('')
  const [platforms, setPlatforms] = useState<string[]>();
  const [languages, setLanguages] = useState<string[]>(); 
  const [productTypes, setProductTypes] = useState<ProductType[]>();
  const [visibleProductTypeFilter, setVisibleProductTypeFilter] =
    useState(false);
  const [visiblePlatformFilter, setVisiblePlatformFilter] = useState(false);
  const [visibleUnderDemandMaterial, setVisibleUnderDemandMaterial] =
    useState(false);
  const [visibleLanguagesFilter, setVisibleLanguagesFilter] = useState(false); 
  const [visibleEndStockFilter, setVisibleEndStockFilter] = useState(false); 
  const [visibleOutletFilter, setVisibleOutletFilter] = useState(false); 
  const { filters, setFilters } = useFilter();
  const [clearFilters, setClearFilters] = useState(false);

  //Multiple filters approach
  const handleFilterChange = (
    filterName: keyof FilterType,
    filter: string | undefined | boolean,
  ) => {
    setFilters((prevFilters) => {
      if (
        typeof filter === 'string' &&
        (filterName === 'platform'  ||
          filterName === 'productType' || filterName === 'idLanguage')
      ) {
        if (prevFilters[filterName].includes(filter)) {
          return {
            ...prevFilters,
            [filterName]: prevFilters[filterName].filter((f) => f !== filter),
          };
        } else {
          if(filterName == 'idLanguage'){
            return {
              ...prevFilters,
              [filterName]: prevFilters[filterName].concat(filter),
            };
          }
          else{
            return {
              ...prevFilters,
              [filterName]: prevFilters[filterName].concat(filter.toUpperCase()),
            };
          } 
        }
      } else {
        return {
          ...prevFilters,
          [filterName]: prevFilters[filterName] === undefined ? filter : undefined,
        };
      }
    });
  };

  const handleClearFilters = () => {
    setFilters({
      platform: [],
      productType: [],
      isOnDemand: undefined,
      available: undefined,
      idLanguage: [], 
      endOfStock: false
    });
    setClearFilters(!clearFilters);
  };

  const handleShowProductType = () => {
    setVisibleProductTypeFilter(!visibleProductTypeFilter);
  };
  const handleShowPlatform = () => {
    setVisiblePlatformFilter(!visiblePlatformFilter);
  };
  const handleShowLanguages = () => {
    setVisibleLanguagesFilter(!visibleLanguagesFilter); 
  }; 
  /*
  const handleShowUnderDemandMaterial = () => {
    setVisibleUnderDemandMaterial(!visibleUnderDemandMaterial);
  };
  */
  const handleShowEndStockMaterial = () => {
    setVisibleEndStockFilter(!visibleEndStockFilter); 
  }; 
  const handleShowOutlet = () =>{
    setVisibleOutletFilter(!visibleOutletFilter); 
  }


  useEffect(() => {
    const idLanguage = localStorage.getItem('idLanguage') || 'es';
    setIdLanguageUser(idLanguage);
    PlatformService.GetPlatformsOfTheProducts().then((response) => {
      const responseData = response.data as string[];
      setPlatforms(responseData);
    });
    ProductTypeService.GetProductTypeByLanguage(idLanguage).then((response) => {
      const responseData = response.data as ProductType[];
      setProductTypes(responseData);
    });
    LanguageService.GetLanguagesOfTheProducts().then((response) => {
      const responseData = response.data as string[]; 
      setLanguages(responseData); 
    })
  }, [clearFilters]);

  return (
    <ScrollPanel style={{ width: '20%', height: 'calc(100vh - 50px)' }}>
      <Container className="hole-filter-bar">
        <Row>
          
          <Col md={4}>
            <Row>
              <div
                className="product-type-container"
                onClick={handleShowProductType}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
    
                <h2
                  style={{
                    fontFamily: 'Graphik Light',
                    fontSize: '15px',
                    marginRight: 'auto',
                  }}
                >
                  {t('filter_component.type_product_header')}
                </h2>
                <div style={{ marginLeft: 'auto' }}>
                  {visibleProductTypeFilter ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </div>
              </div>
              <hr
                style={{
                  margin: '0 8px',
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#000',
                }}
              />
            </Row>
            <Row>
              <Col>
                {visibleProductTypeFilter &&
                  productTypes &&
                  productTypes.map((productType) => (
                    <label key={productType.name} className="filter-tags">
                      <input
                        type="checkbox"
                        name="test"
                        checked={filters.productType.includes(productType.name)}
                        value={productType.name || ''}
                        onChange={() =>
                          handleFilterChange('productType', productType.name)
                        }
                      ></input>
                      <span className="square-checkbox"></span>
                      {productType.description.toLocaleLowerCase()}
                    </label>
                  ))}
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <div
              className="product-type-container"
              onClick={handleShowPlatform}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <h2 style={{ fontFamily: 'Graphik Light', fontSize: '15px' }}>
                {t('filter_component.platform_header')}
              </h2>
              <div style={{ marginLeft: 'auto' }}>
                {visiblePlatformFilter ? (
                  <MdKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}
              </div>
            </div>
            <hr
              style={{
                margin: '0 8px',
                width: '100%',
                height: '1px',
                backgroundColor: '#000',
              }}
            />

            <Row>
              <Col>
                {visiblePlatformFilter &&
                  platforms &&
                  platforms.map((platform, index) => (
                    <label key={index} className="filter-tags">
                      <input
                        type="checkbox"
                        name="test"
                        checked={filters.platform.includes(platform)}
                        value={platform || ''}
                        onChange={() =>
                          handleFilterChange('platform', platform)
                        }
                      ></input>
                      <span className="square-checkbox"></span>
                      {platform.toLocaleLowerCase()}
                    </label>
                  ))}
              </Col>
            </Row>
          </Col> 
          <Col md={4}>
            <Row>
              <div
                className="product-type-container"
                onClick={handleShowLanguages}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
    
                <h2
                  style={{
                    fontFamily: 'Graphik Light',
                    fontSize: '15px',
                    marginRight: 'auto',
                  }}
                >
                  {t('filter_component.languages.header')}
                </h2>
                <div style={{ marginLeft: 'auto' }}>
                  {visibleLanguagesFilter ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </div>
              </div>
              <hr
                style={{
                  margin: '0 8px',
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#000',
                }}
              />
            </Row>
            <Row>
              <Col>
                {visibleLanguagesFilter &&
                  languages &&
                  languages.map((language, index) => (
                    <label key={index} className="filter-tags">
                      <input
                        type="checkbox"
                        name="test"
                        checked={filters.idLanguage.length > 0 ? filters.idLanguage.includes(language) : language === idLanguageUser}
                        value={language || ''}
                        onChange={() =>
                          handleFilterChange('idLanguage', language)
                        }
                      ></input>
                      <span className="square-checkbox"></span>
                      {t(`filter_component.languages.keys.${language}`)}
                    </label>
                  ))}
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <div
                className="product-type-container"
                onClick={handleShowOutlet}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
    
                <h2
                  style={{
                    fontFamily: 'Graphik Light',
                    fontSize: '15px',
                    marginRight: 'auto',
                  }}
                >
                  {t('filter_component.outlet.header')}
                </h2>
                <div style={{ marginLeft: 'auto' }}>
                  {visibleOutletFilter ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </div>
              </div>
              <hr
                style={{
                  margin: '0 8px',
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#000',
                }}
              />
            </Row>
            <Row>
              <Col>
              {visibleOutletFilter ? (
                  <><label className="filter-tags">
                    <input
                      type="checkbox"
                      name="test"
                      className='sidebar-input'
                      checked={filters.isOnDemand}
                      onChange={() => handleFilterChange('isOnDemand', !filters.isOnDemand)}
                    ></input>
                    <span className="square-checkbox"></span>
                    {t('filter_component.outlet.keys.on_demand')}
                  </label>
                  <label className="filter-tags">
                      <input
                        type="checkbox"
                        name="test"
                        className='sidebar-input'
                        checked={filters.endOfStock}
                        onChange={() => handleFilterChange('endOfStock', !filters.endOfStock)}
                      ></input>
                      <span className="square-checkbox"></span>
                      {t('filter_component.outlet.keys.outlet')}
                    </label></>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>

        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div
            style={{ position: 'fixed', bottom: 0, left: 0, padding: '10px' }}
          >
            <button
              className="meso-button-filter"
              onClick={() => handleClearFilters()}
            >
              {t('filter_component.erase_filters')}
            </button>
          </div>
        </Container>
      </Container>
    </ScrollPanel>
  );
};

export default FilterSideBar;
