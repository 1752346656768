import React from 'react';
import { useTranslation } from 'react-i18next';
import './Modal.css';

type ModalProps = {
  children: React.ReactNode;
  onClose: () => void;
  onAccept: () => void;
  onCancel: () => void;
  items: React.ReactNode;
};

export const Modal: React.FC<ModalProps> = ({
  onAccept,
  onCancel,
  children,
  items,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <p className="close" onClick={() => onClose()}>
            &times;
          </p>
        </div>
        <div className="modal-content">
          <h1>{t('sidebar_component.confirm_dialog.title')}</h1>
        </div>
        {children}
        <hr></hr>
        {items}
        <div className="modal-footer">
          <button className="meso-button-accept" onClick={() => onAccept()}>
            {t('sidebar_component.confirm_dialog.accept_btn')}
          </button>
          <button className="meso-button-reject" onClick={() => onCancel()}>
            {t('sidebar_component.confirm_dialog.reject_btn')}
          </button>
        </div>
      </div>
    </div>
  );
};
