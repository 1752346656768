import { Configuration, PopupRequest } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority:
      'https://login.microsoftonline.com/425835a2-4b10-4977-bf72-f9f1a1bf2864',
    redirectUri: 'http://localhost:5173',
    postLogoutRedirectUri: '/',
  },
  system: {
    allowNativeBroker: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
};

export const apiRequest: PopupRequest = {
  scopes: [`${process.env.REACT_APP_API_SCOPE}`],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
