import axios from 'axios';
import PurchaseOrder from '../models/PurchaseOrder';

export const PurchaseOrderService = {
  PostPurchaseOrderAX: async (purchasesItemsAX: PurchaseOrder[]) => {
    try {
      console.log('PostPurchaseOrderAX -> purchasesItemsAX:', purchasesItemsAX);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/PurchaseOrder`,
        purchasesItemsAX,
      );
      return response.data;
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request data:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
      throw error;
    }
  },
};
