import React, { useEffect } from 'react';
import DarkMenuBar from '../../components/DarkMenuBar/DarkMenuBar';
import { AuthService } from '../../services/auth.service';
import DelegateCustomer from '../../models/DelegateCustomer';
import { useState } from 'react';
import DropdownAutocomplete from '../../components/AutocompleteSearch/DropdownAutocomplete';

export default function ChoiceCustomer() {
  return (
    <div>
      <DarkMenuBar />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DropdownAutocomplete></DropdownAutocomplete>
      </div>
    </div>
  );
}
