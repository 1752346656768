import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataView } from 'primereact/dataview';
import { Divider } from 'primereact/divider';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import CartItem from '../../models/CartItem';
import { EmailService } from '../../services/email.service';
import { PurchaseOrderService } from '../../services/purchaseOrder.service';
import { Modal } from '../Modal/Modal';
import './SideBar.css';

interface SideBarProps {
  visibleSidebar: boolean;
  setVisibleSideBar: (visible: boolean) => void;
  toEmail: string;
}

export default function SideBar({
  visibleSidebar,
  setVisibleSideBar,
  toEmail,
}: SideBarProps) {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const {
    cartItems,
    increaseCartQuantity,
    decreaseCartQuantity,
    setQuantity,
    removeFromCart,
    emptyCart,
  } = useShoppingCart();
  //const [purchaseOrdersAX, setPurchaseOrdersAX] = useState<PurchaseOrder[]>([]);
  const toast = useRef<Toast>(null);
  const [idCompany, setIdCompany] = useState('');
  const [idClient, setIdClient] = useState('');
  const [idLanguage, setIdLanguage] = useState('');
  const [isDelegate, setIsDelegate] = useState(false);
  const navigate = useNavigate();
  const api = localStorage.getItem('api') ?? '';

  useEffect(() => {
    const isDelegate = localStorage.getItem('isDelegate') ?? 'false';
    getLanguageForEmail();
    if (isDelegate === 'true') {
      setIsDelegate(true);
      const idCompany = localStorage.getItem('idCompanyClient') ?? 'meso';
      setIdCompany(idCompany);
      const idClient = localStorage.getItem('idDelegateClient') ?? '';
      setIdClient(idClient);
    } else {
      setIsDelegate(false);
      const idCompany = localStorage.getItem('idCompany') ?? 'meso';
      setIdCompany(idCompany);
      const idClient = localStorage.getItem('idClient') ?? '';
      setIdClient(idClient);
    }
  }, []);

  const accept = async () => {
    try {
      // await checkIfDelegateAndSetVariables() JUAN

      await purchaseItems();

      await sendEmail(toEmail);

      if (isDelegate) {
        navigate('/choiceCustomer');
      }
    } catch (error) {
      console.error('Error occurred while processing:', error);
      toast.current?.show({
        severity: 'error',
        summary: t('sidebar_component.toast_error_purchase.summary'),
        detail: t('sidebar_component.toast_error_purchase.detail'),
        life: 3000,
      });
    }
  };

  async function purchaseItems() {
    try {
      const newPurchaseItemsAX = cartItems.map((cartItem) => {
        return {
          idPurchaseOrder: `P2B_${Date.now()}`,
          idCompany: idCompany,
          idClient: idClient,
          partition: 5637144576,
          recVersion: 1,
          quantity: cartItem.quantity,
          price: cartItem.price,
          code: cartItem.idProduct.toString(),
          address: toEmail,
        };
      });

      //setPurchaseOrdersAX(newPurchaseItemsAX);

      await PurchaseOrderService.PostPurchaseOrderAX(newPurchaseItemsAX);

      toast.current?.show({
        severity: 'info',
        summary: t('sidebar_component.toast_confirmation_purchase.summary'),
        detail: t('sidebar_component.toast_confirmation_purchase.detail'),
        life: 3000,
      });

      emptyCart();
      setVisibleModal(false);
    } catch (error) {
      console.error('Error occurred while posting purchase orders:', error);
      throw new Error('Purchase order submission failed');
    }
  }
  function getLanguageForEmail() {
    const idLanguage = localStorage.getItem('idLanguage') ?? '';
    switch (idLanguage) {
      case 'es':
        setIdLanguage(idLanguage);
        break;
      default:
        setIdLanguage('en-us');
        break;
    }
  }
  async function sendEmail(toEmail: string) {
    try {
      await EmailService.Send(toEmail, idLanguage);
    } catch (error) {
      console.error('Error occurred while sending email:', error);
      throw new Error('Email sending failed');
    }
  }

  // function checkIfDelegateAndSetVariables() { JUAN
  //   const isDelegate = localStorage.getItem('isDelegate') === 'true';

  //   // TODO: @Paula - ¿son correctes les dades per defecte?
  //   if (isDelegate) {
  //     const idCompany = localStorage.getItem('idCompanyClient') ?? 'meso';
  //     setIdCompany(idCompany);
  //     const idClient = localStorage.getItem('idDelegateClient') ?? '';
  //     setIdClient(idClient);
  //   } else {
  //     const idCompany = localStorage.getItem('idCompany') ?? 'meso';
  //     setIdCompany(idCompany);
  //     const idClient = localStorage.getItem('idClient') ?? '';
  //     setIdClient(idClient);
  //   }
  // }

  const handleQuantityChange = (idProduct: string, quantity: number) => {
    if (quantity <= 0) {
      removeFromCart(idProduct);
    } else {
      setQuantity(idProduct, quantity);
    }
  };

  const itemTemplate = (cartItems: CartItem[], layout: string | undefined) => {
    return cartItems.map((product, index) => (
      <div key={product.idProduct}>
        <div
          className={classNames(
            'flex flex-column xl:flex-row xl:align-items-start p-4 gap-4',
            { 'border-top-1 surface-border': index !== 0 },
          )}
        >
          <img
            className="w-9 sm:w-10rem xl:w-8rem shadow-2 block xl:block mx-auto border-round"
            src={product.picture}
            alt={product.displayName}
            style={{
              maxWidth: '40px',
              maxHeight: '70px',
              width: 'auto',
              height: 'auto',
            }}
          />
          <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <div className="flex flex-column align-items-center sm:align-items-start gap-3">
              <div
                className="text-s font-normal text-900"
                style={{ fontFamily: 'Graphik Light', fontSize: 'small' }}
              >
                {product.displayName.toLocaleLowerCase()}
              </div>
              <div className="counter-div">
                <p
                  className="icon-substract-add"
                  onClick={() => decreaseCartQuantity(product.idProduct)}
                >
                  -
                </p>
                <input
                  type="text"
                  value={product.quantity}
                  className="sidebar-input"
                  disabled={product.lowest > 1}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (!isNaN(value)) {
                      handleQuantityChange(product.idProduct, value);
                    }
                  }}
                />
                <p
                  className="icon-substract-add"
                  onClick={() =>
                    increaseCartQuantity(
                      product.idProduct,
                      product.displayName,
                      product.price,
                      product.picture,
                      product.lowest,
                      product.multiple,
                    )
                  }
                >
                  +
                </p>
                <i
                  className="pi pi-trash"
                  onClick={() => removeFromCart(product.idProduct)}
                ></i>
              </div>
            </div>
            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
              <span
                className="text-l font-normal"
                style={{ fontFamily: 'Graphik Light', fontSize: 'small' }}
              >
                {product.price.toFixed(2)}€
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="card flex justify-content-center">
      <Sidebar
        visible={visibleSidebar}
        onHide={() => setVisibleSideBar(false)}
        position="right"
        style={{ position: 'relative', zIndex: 9999 }}
      >
        <h2 className="sidebar-title">{t('sidebar_component.title')}</h2>
        <DataView value={cartItems} listTemplate={itemTemplate}></DataView>
        <Toast ref={toast} />
        <ConfirmDialog />
        <div
          className="card flex flex-wrap gap-2 justify-content-center"
          style={{ marginTop: 'auto', marginBottom: '10rem' }}
        >
          <Divider></Divider>
          <div
            className="text-s font-normal text-900"
            style={{ fontFamily: 'Graphik Light' }}
          >
            <strong>{t('sidebar_component.total')}</strong>{' '}
            {cartItems
              .reduce(
                (accumulator, currentItem) =>
                  accumulator + currentItem.price * currentItem.quantity,
                0,
              )
              .toFixed(2)}{' '}
            €
          </div>
          <Divider />
        </div>
        <div
          className="card flex flex-wrap gap-2 justify-content-center"
          style={{ marginTop: '1rem' }}
        >
          <button
            className={`meso-button-sidebar${cartItems.length === 0 ? '-disabled' : ''}`}
            disabled={cartItems.length === 0}
            onClick={() => {
              setVisibleModal(true);
            }}
          >
            {t('sidebar_component.purchase_order_btn')}
          </button>
          {visibleModal && (
            <Modal
              onAccept={accept}
              onCancel={() => setVisibleModal(false)}
              onClose={() => setVisibleModal(false)}
              items={
                <p>{`${cartItems.length} ${t('sidebar_component.confirm_dialog.items')}`}</p>
              }
            >
              <p>{t('sidebar_component.confirm_dialog.question')}</p>
            </Modal>
          )}
          <button
            className="meso-button-sidebar"
            onClick={() => {
              setVisibleSideBar(false);
            }}
          >
            {t('sidebar_component.hide_sidebar')}
          </button>
        </div>
      </Sidebar>
    </div>
  );
}
