import { ScrollPanel } from 'primereact/scrollpanel';
import { FC } from 'react';
import PictureProduct from '../../models/PictureProduct';
import Product from '../../models/Product';
import ProductCard from './ProductCard';

interface Props {
  products: Product[];
  //stocks: Stock[];
  profilePictures: PictureProduct[];
}

export const ProductCardList: FC<Props> = ({
  products,
  //stocks,
  profilePictures,
}) => {
  return (
    <div style={{ marginLeft: '4%' }}>
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 50px)' }}>
        {products.map((product) => (
          <div className="card-holder" key={product.idProduct}>
            <ProductCard
              key={product.idProduct}
              idProduct={product.idProduct}
              imgUrl={
                profilePictures.find(
                  (profilePicture) => profilePicture.idProduct === product.code,
                )?.urlProfilePicture ?? '/images/no-image.jpg'
              }
              nameProduct={product.nameProduct ?? ''}
              price={product.price ?? 0}
              code={product.code ?? ''}
              available={product.availableWW > 0}
              isOnDemand={product.isOnDemand}
              lowest={product.lowest ?? 1}
              multiple={product.multiple ?? 1}
              isEnabled={product.isEnabled ?? ""}
            />
          </div>
        ))}
      </ScrollPanel>
    </div>
  );
};
