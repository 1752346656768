import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import ReactDOM from 'react-dom/client';
import App from './App';
import { msalConfig } from './authConfig';
import './index.css';
import { AxiosInterceptor } from './interceptors/interceptor';
import reportWebVitals from './reportWebVitals';
import './translations/i18n';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  AxiosInterceptor();
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <App instance={msalInstance} />,
  );
});

reportWebVitals();
