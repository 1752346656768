import React, { useState } from 'react';
import './ImageModal.css'; 

interface ImageModalProps {
  imgUrl: string;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ imgUrl, onClose }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div className="image-modal-overlay" onClick={onClose}>
      <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
        <img 
          src={imgUrl} 
          alt="Enlarged view" 
          className={`image-modal-img ${isZoomed ? 'zoomed' : ''}`} 
          onClick={handleImageClick}
        />
      </div>
    </div>
  );
};

export default ImageModal;
