import axios from 'axios';

export const PictureProfileService = {
  GetPicturesProduct: async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/pictureProduct`,
    );
    return response;
  },
};
