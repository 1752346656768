import axios from 'axios';

const url = process.env.REACT_APP_API_URL;

export const EmailService = {
  Send: async (toEmail: string, idLanguage: string) => {
    const response = await axios.post(
      `${url}/email/send?toEmail=${toEmail}&idLanguage=${idLanguage}`,
    );
    return response;
  },
};
