import { useMsal } from '@azure/msal-react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useBusinessUnit } from '../../context/BusinessUnitContext';
import BusinessUnit from '../../models/BusinessUnit';
import { getBusinessUnits } from '../../services/businessUnit.service';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import SideBar from '../SideBar/SideBar';
import './DarkMenuBar.css';

const DarkMenuBar = () => {
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
  const [click, setClick] = useState(false);
  const [toEmail, setToEmail] = useState('');
  const handleLateralMenu = () => setClick(!click);
  const [visibleSidebar, setVisibleSideBar] = useState(false);
  const op = React.useRef<OverlayPanel>(null);
  const { cartItems } = useShoppingCart();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { businessUnitKey, setBusinessUnitKey, setBusinessUnitName } =
    useBusinessUnit();

  const handleProductTypeClick = (
    newBusinessUnitKey: string,
    newBusinessUnitName: string,
  ) => {
    setClick(false);
    if (businessUnitKey === newBusinessUnitKey) {
      setBusinessUnitKey('');
      setBusinessUnitName('');
      return;
    }
    setBusinessUnitKey(newBusinessUnitKey);
    setBusinessUnitName(newBusinessUnitName);
  };

  const handleLogoutPopup = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: '/',
      account: instance.getActiveAccount(),
    });
  };

  useEffect(() => {
    const toEmail = localStorage.getItem('toEmail') || '';
    setToEmail(toEmail);
    const fetchData = async () => {
      try {
        const data = await getBusinessUnits();
        setBusinessUnits(data);
      } catch (error) {
        console.error('Error al recuperar las business units:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">
        <img
          alt="logo"
          src="/images/meso-small-white.png"
          style={{ height: 20, paddingRight: 20, paddingLeft: 20 }}
        ></img>
      </Link>
      <div className="navbar-container">
        <div className="menu-icon" onClick={handleLateralMenu}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul>
          {businessUnits.map((bu) => (
            <li className="nav-btn" key={bu.key}>
              <div
                className={
                  'nav-links ' +
                  (businessUnitKey === bu.key ? 'nav-links-active' : '')
                }
                onClick={() => handleProductTypeClick(bu.key, bu.name)}
              >
                {t(`menu_component.${bu.name}`)}
              </div>
            </li>
          ))}
          <Button
            icon={
              <>
                <img
                  src="/images/shopping-bag.png"
                  alt="shopping-cart"
                  style={{ height: '1.5rem' }}
                />
                {cartItems.length > 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      right: '10px',
                      borderRadius: '50%',
                      fontFamily: 'Graphik Light',
                      fontSize: 'small',
                      color: 'white',
                      backgroundColor: 'red',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      transform: 'translate(50%, 50%)',
                    }}
                  >
                    {cartItems.length}
                  </div>
                )}
              </>
            }
            outlined
            severity="info"
            aria-label="Shop"
            style={{
              borderColor: 'white',
              color: 'white',
              backgroundColor: 'transparent',
              marginRight: '10px',
            }}
            onClick={() => setVisibleSideBar(!visibleSidebar)}
          />
          <Button
            icon={
              <img
                src="/images/user.png"
                alt="user"
                style={{ height: '1.6rem' }}
              />
            }
            severity="info"
            aria-label="Shop"
            style={{
              borderColor: 'white',
              color: 'white',
              backgroundColor: 'transparent',
              marginRight: '10px',
            }}
            onClick={(e) => op.current && op.current.toggle(e)}
          ></Button>
          <OverlayPanel ref={op}>
            <button onClick={handleLogoutPopup}>Logout</button>
          </OverlayPanel>
        </ul>

        <SideBar
          visibleSidebar={visibleSidebar}
          setVisibleSideBar={setVisibleSideBar}
          toEmail={toEmail}
        />
      </div>
    </nav>
  );
};

export default DarkMenuBar;
