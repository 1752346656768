import { useState } from 'react';
import { BusinessUnitContext } from '../../context/BusinessUnitContext';

export function BusinessUnitProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [businessUnitKey, setBusinessUnitKey] = useState<string>('');
  const [businessUnitName, setBusinessUnitName] = useState<string>('');

  return (
    <BusinessUnitContext.Provider
      value={{
        businessUnitKey,
        setBusinessUnitKey,
        businessUnitName,
        setBusinessUnitName,
      }}
    >
      {children}
    </BusinessUnitContext.Provider>
  );
}
