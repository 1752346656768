export const getBusinessUnits = async () => {
  try {
    const response = await fetch('/data/businessUnits.json');

    if (!response.ok) {
      throw new Error('Error al recuperar los datos de las business units');
    }

    const businessUnits = await response.json();

    return businessUnits;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
