import axios from 'axios';

export const ProductTypeService = {
  GetProductTypeByLanguage: async (language: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/productType/language/${language}`,
    );
    return response;
  },
};
