import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import global_es from './languages/es-ES.json';
import global_en from './languages/en-EN.json';
import global_pt from './languages/pt-PT.json';
import global_fr from './languages/fr-FR.json';
import global_it from './languages/it-IT.json';

i18n.use(initReactI18next).init({
  lng: navigator.language,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: global_en,
    },
    es: {
      translation: global_es,
    },
    fr: {
      translation: global_fr,
    },
    it: {
      translation: global_it,
    },
    pt: {
      translation: global_pt,
    },
  },
});

export default i18n;
