import { useMsal } from '@azure/msal-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { apiRequest, loginRequest } from '../../authConfig';
import Client from '../../models/Client';
import { AuthService } from '../../services/auth.service';
import './Splash.css';

const SplashScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const toast = useRef<Toast>(null);
  const [visibleToast, setVisibleToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAuthenticationPopUp = () => {
    instance
      .loginPopup({
        ...loginRequest,
        redirectUri: '/redirect',
      })
      .then((response) => {
        setIsLoading(true);

        if (
          response.accessToken != null &&
          response.account?.username != null
        ) {
          localStorage.setItem('login', response.accessToken);
          const account = response.account?.username;
          localStorage.setItem('toEmail', account);

          // Obtener el token para la API backend
          const apiTokenRequest = {
            ...apiRequest,
            account: accounts[0],
          };

          instance
            .acquireTokenSilent(apiTokenRequest)
            .then((apiResponse) => {
              localStorage.setItem('api', apiResponse.accessToken);

              AuthService.GetAuthClient(account)
                .then((authResponse) => {
                  if (authResponse && authResponse.data) {
                    const responseData = authResponse.data as Client;
                    if (
                      responseData != null &&
                      responseData.idLanguage !== undefined &&
                      responseData.idClient !== undefined &&
                      responseData.idCompany !== undefined
                    ) {
                      saveClientVariables(responseData);
                      if (responseData.isDelegate == true) {
                        localStorage.setItem('isDelegate', 'true');
                        navigate('/choiceCustomer');
                      } else {
                        localStorage.setItem('isDelegate', 'false');
                        navigate('/products');
                      }
                    } else {
                      throw new Error('User not registered in the system.');
                    }
                  } else {
                    toast.current?.show({
                      severity: 'error',
                      summary: t('splash.toasts.error_database.summary'),
                      detail: t('splash.toasts.error_database.detail'),
                    });
                  }
                })
                .catch((error) => {
                  console.error('Error in GetAuthClient:', error);
                  setIsLoading(false);
                });
            })
            .catch((error) => {
              console.error('Error in acquireTokenSilent:', error);
              setIsLoading(false);
              toast.current?.show({
                severity: 'error',
                summary: t('splash.toasts.error_microsoft.summary'),
                detail: t('splash.toasts.error_microsoft.detail'),
              });
            });
        } else {
          console.error('Authentication failed');
          toast.current?.show({
            severity: 'error',
            summary: t('splash.toasts.error_microsoft.summary'),
            detail: t('splash.toasts.error_microsoft.detail'),
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error in loginPopup:', error);
        setIsLoading(false);
      });
  };

  const saveClientVariables = (response: any) => {
    localStorage.setItem('idLanguage', response.idLanguage);
    localStorage.setItem('idClient', response.idClient);
    localStorage.setItem('idCompany', response.idCompany);
    localStorage.setItem('email', response.email);
    localStorage.setItem('displayName', response.name);
    localStorage.setItem('isCustomUser', response.isCustomUser == true ? 'true' : 'false'); 
  };

  return (
    <div className="background">
      <img
        alt="long-logo"
        className="long-logo"
        src="/images/logo_mesoestetic.png"
      />
      <img
        alt="merchan-logo"
        className="merchan-logo"
        src="/images/merchandising.png"
      />
      <Toast ref={toast}></Toast>
      {isLoading ? (
        <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div>
      ) : (
        <button
          type="submit"
          className="microsoft-button"
          onClick={handleAuthenticationPopUp}
        >
          {t('splash.login')}
        </button>
      )}
    </div>
  );
};

export default SplashScreen;
