import axios from 'axios';

export const AuthService = {
  GetAuthClient: async (email: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/auth`,
        {
          params: {
            email: email,
          },
        },
      );
      return response;
    } catch (error) {
      console.error('API call failed:', error);
    }
  },
  GetCustomersOfDelegate: async (email: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/delegates?email=${email}`,
    );
    return response;
  },
};
